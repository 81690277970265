import React, { Component } from 'react'
import './Payment.css';
import { config } from '../../constants/AppConfig';

// import { AiOutlineEye } from 'react-icons/ai';
import axios from 'axios';
// import Swal from 'sweetalert2';

import Header from '../navigation/Header';
import Footer from '../navigation/Footer';


import {
    Navigate
} from 'react-router-dom';

import {
    Container,
    Col,
    Row,
} from 'react-bootstrap';

import Loading from '../../components/Loading';

export default class PaymentFail extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            // loading: false,
            redirectTo: '',
            redirect: false,

            nomClient: '',
            numTrans: '',
        }
    };

    componentDidMount = async () => {

        let url = window.location;

        let search_params = new URLSearchParams(url.search);

        if( search_params.has('orderId') ) {
            let orderIdFromLink = search_params.get('orderId');
            let orderIdFromLocal = await localStorage.getItem('orderId');
            let activeFacturesFromLocal = await JSON.parse(localStorage.getItem('Factures'));
            let NumTransactionFromLocal = await localStorage.getItem('numTransaction');
            let clientName = await localStorage.getItem('nomClient');

            this.setState({ 
                nomClient: clientName,
                numTrans: NumTransactionFromLocal,
            });

            // console.log("Comapriiing",orderIdFromLink, "=>>" ,orderIdFromLocal);

            // console.log("Factures",activeFacturesFromLocal);
            // console.log("numTrans", NumTransactionFromLocal);

            if(orderIdFromLink === orderIdFromLocal && orderIdFromLink !== null) {
                
                // Send To BackEnd Payment Status:
                axios.post(`${config.urlBackExp}transactionSuccess`,{
                    Factures: activeFacturesFromLocal,
                    OrderId: orderIdFromLocal,
                    NumTransaction: NumTransactionFromLocal,
                    Status: 'fail',
                })
                .then(async rep => {
                    localStorage.clear();
                    this.setState({
                        loading: false,
                    });
                })
                .catch(err =>{
                    // console.log("Erreur Paiement",err)
                });
            }
        } else {
            this.setState({
                loading: false,
                redirectTo: '/',
                redirect: true,
            })
        }
    };


    render() {
       let {
           loading,
           redirectTo,
           redirect,
           nomClient,
           numTrans,
       } = this.state;

       if(loading) {
           return (<Loading />)
       } else {
           return (
           <>
          
               {/* HEADER */}
               <Header />
               {/* HEADER */}
               
               {redirect &&  <Navigate from='/payment-success' to={redirectTo} />}

               <Row className="backimage">
                   <Col className="d-flex">
                       <h1 className="textBack"> STATUS PAIEMENT </h1>
                   </Col>
               </Row>
   
               <Container fluid={window.innerWidth > 1200 ? true : false}>
                   <Row>
                       <Col xs={0} md={0} lg={0} xl={1} />
   
                       <Col xs={12} md={12} lg={12} xl={10} className="leftFactureContainer">
                            <div className="payTitle">Erreur de paiement</div>

                            <div className="payName">Bonjour M/MME {nomClient} - </div>

                            <div className="payDesc">Nous vous informons que le paiement des factures ayant le numéro de transaction {numTrans} a été réfusé</div>

                            <div className="payName">Nous vous remercions pour votre fidélité</div>
                       </Col>
   
                       <Col xs={0} md={0} lg={0} xl={1} />
                   </Row>
               </Container>
   
               {/* FOOTER */}
               <Footer />
               {/* FOOTER */}
           </>
           );
       }
    };

    
}
