import React, { Component } from 'react'
import './Factures.css';
import { config } from '../../constants/AppConfig';

import axios from 'axios';
import Swal from 'sweetalert2';

import Header from '../navigation/Header';
import Footer from '../navigation/Footer';


import {
    Navigate
} from 'react-router-dom';

import {
    Container,
    Col,
    Row,
    Button,
    Spinner
} from 'react-bootstrap';

// import NewWindow from 'react-new-window'
// import moment from 'moment';

// const FACTURE_IMAGE = require('../../assets/images/factures/pay-back.png');


export default class Factures extends Component {

    constructor(props) {

        super(props);

        this.state = {
            isAuth: true,
            cin: null,
            adsl: null,
            client: null,
            clientName: null,
            clientCode: null,
            allClient: [],
            totalApayer: 0,
            remis: 0,
            factures: [],
            activeFactures: [],
            checkboxes: [],
            lengthFac: 0,

            // Redirection :
            redirect: false,
            redirectTo: '',
            btnPayLoading: false,

            // TEST :
            repServer: undefined,
            errServer: undefined,

        }
        this.getFactures();
    };

    componentDidMount() {
        setInterval(() => this.testSession(), 900000);
    };

    render() {
        let { factures, redirect, redirectTo, activeFactures } = this.state;

        return (
            <>
                {redirect && <Navigate to={redirectTo} />}

                {/* HEADER */}
                <Header showLogout={true} />
                {/* HEADER */}


                <Row className="backimage">
                    <Col className="d-flex">
                        <h1 className="textBack"> Mes factures </h1>
                    </Col>
                </Row>

                <Container fluid={window.innerWidth > 1200 ? true : false}>
                    <Row>
                        <Col xs={0} md={0} lg={0} xl={1} />

                        <Col xs={11} md={11} lg={7} xl={6} className="leftFactureContainer">
                            {typeof (factures) === "string" && this.renderEmptyFactures()}

                            {typeof (factures) === "object" && factures.length > 0 && this.renderFactures(factures)}
                        </Col>

                        <Col xs={11} md={11} lg={5} xl={4} className="rightFactureContainer">
                            {factures.length > 0 && this.renderDetailsFactures(activeFactures)}
                        </Col>

                        <Col xs={0} md={0} lg={0} xl={1} />
                    </Row>
                </Container>

                {/* FOOTER */}
                <Footer />
                {/* FOOTER */}
            </>
        );
    };

    renderEmptyFactures = () => {

        return (
            <>
                <Col className={window.innerWidth > 767 ? "ml-3 mt-3" : "mt-3"}>
                    <p className="leftCardTitle">Liste des factures</p>
                </Col>

                <Row className={window.innerWidth > 767 ? "ml-3" : ""}>
                    <Col xs={12} md={10} lg={12} xl={10} className="d-flex justify-content-between">
                        <p className="leftCardMiniTitle">Client {this.state.clientName}</p>
                    </Col>

                    <Col xs={0} md={2} lg={0} xl={2} />
                </Row>

                <Col className="d-flex">
                    <p className="emptyFactsText m-auto">Vous n'avez aucune facture à payer</p>
                </Col>

            </>
        );
    };

    renderFactures = (factures) => {
        if (factures.length > 0) {
            return (
                <>

                    <Col className={window.innerWidth > 767 ? "ml-3 mt-3" : "mt-3"}>
                        <p className="leftCardTitle">Liste des factures</p>
                    </Col>

                    <Row className={window.innerWidth > 767 ? "ml-3" : ""}>
                        <Col xs={12} md={10} lg={12} xl={10} className="d-flex justify-content-between">
                            <p className="leftCardMiniTitle">Client {this.state.clientName}</p>
                            <p className="leftCardMiniTitle">Sélectionnez les factures à payer </p>
                        </Col>
                        <Col xs={0} md={2} lg={0} xl={2} />
                    </Row>

                    <div className={factures.length > 3 ? "scrollableLeft" : ""}>
                        {
                            factures.map((fact, index) =>

                                <Row className={window.innerWidth > 767 ? "ml-3 mt-3" : "mt-3"} key={index}>

                                    <Col xs={10} md={10} lg={10} xl={10} className="facture-card">
                                        <Row className="d-flex justify-content-between">
                                            <Col xs={12} md={9} lg={9} xl={9}>
                                                <p className="factureTitle">Num. Facture : {fact.NumeroFacture} du {fact.DateFacture}</p>
                                            </Col>

                                            <Col className="d-flex" xs={12} md={3} lg={3} xl={3}>
                                                <p className="total-facture ml-auto">{parseFloat(fact.Montant_TTC).toFixed(3)} dt</p>
                                            </Col>
                                        </Row>

                                        {/* <Row className="d-flex justify-content-between">
                                            <Col xs={10} md={10} lg={8} xl={9}>
                                                <Button className="voir-facture-btn" variant="primary">Visualiser la facture  <AiOutlineEye size={25} /></Button>
                                            </Col>
    
                                            <Col className="mt-2 d-flex" xs={12} md={10} lg={4} xl={3}>
                                                <p className="payer-avant ml-auto">A payer avant : {fact.Date_FinPeriode.date.substr(0, 10)}</p>
                                            </Col>
                                        </Row> */}
                                    </Col>

                                    <Col xs={2} md={2} lg={2} xl={2} className="d-flex align-items-center radioFacture">

                                        {/* <input type="checkbox" name="statusRadios2" id={fact.NumeroFacture} onClick={() => this.FacturePourPayer()} ></input> */}
                                        <div className="radioButtonContainer">
                                            <label className="butTypeContainer">
                                                <input type="radio" name="statusRadios2" id={fact.NumeroFacture} onClick={() => this.FacturePourPayer()} />
                                                <span className="btnCheckmark"></span>
                                            </label>
                                        </div>
                                        {/* <div className="containerButFact">
                                            <span className="checkmarkFact"></span>
                                        </div> */}
                                    </Col>

                                </Row>
                            )
                        }
                    </div>

                    {/* FACTURES CONTAINER */}

                    {/* SELECT ALL */}

                    {/* <Row className="ml-3 mt-5">
                        <Col xs={10} md={10} lg={10} xl={10} className="d-flex justify-content-end">
                            <p className="selectAllText">Sélectionnez toutes les factures</p>
                        </Col>
    
                        <Col xs={2} md={2} lg={2} xl={2} className="d-flex radioFacture selectAllButton">
                            
                            <div className="radioButtonContainer">
                                <label className="butTypeContainer">
                                <input  type="checkbox" id="selectAllId" onClick={() => this.selectAll()}  />
                                    <span className="btnCheckmark"></span>
                                </label>
                            </div>
                           
                        </Col>
                    </Row> */}
                    {/* ./SELECT ALL */}

                </>
            );
        };
    };

    renderDetailsFactures = (activeFactures) => {

        return (
            <>
                <Col className="ml-3 mt-3">
                    <p className="rightCardTitle">Client {this.state.clientName}</p>
                    <p className="rightCardPhone">Code client :{this.state.clientCode}</p>
                </Col>

                {/* DETAILS FACTURE */}
                <div className={activeFactures.length > 2 ? "scrollableRight mt-5" : ""}>
                    {
                        activeFactures.length > 0 &&

                        activeFactures.map((detail, index) =>
                            <>
                                <Row className="ml-3 mt-3" key={index}>
                                    <Col xl={12} className="">
                                        <Row className="d-flex justify-content-between">
                                            <Col xl={9}>
                                                <p className="detailsFactureTitle">Num. Facture : {detail.NumeroFacture}   </p>
                                            </Col>

                                            <Col xl={3}>
                                                <p className="detailsFacturePrice">{parseFloat(detail.Montant_TTC).toFixed(3)} dt</p>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xl={8}>
                                                <p className="detailsFactureDate">du {detail.DateFacture}</p>
                                            </Col>

                                            <Col xl={4} />
                                        </Row>
                                    </Col>
                                </Row>
                                {index !== activeFactures.length - 1 && <hr className="hrDetails" />}
                            </>
                        )
                    }
                    {/* DETAILS FACTURE */}

                </div>


                <div className="mt-5 totalContainer">
                    <Col xs={12} md={12} lg={12} xl={12} className="ml-4">
                        <Row className="d-flex justify-content-around">
                            <Col xs={8} md={8} lg={7} xl={8}>
                                <p className="totalText">Total à payer</p>
                            </Col>

                            <Col xs={4} md={4} lg={5} xl={4}>
                                <p className="totalPrice">{parseFloat(this.state.totalApayer).toFixed(3)}dt</p>
                            </Col>
                        </Row>
                        {this.state.remis > 0 && <Row className="d-flex justify-content-around">
                            <Col xs={8} md={8} lg={7} xl={8}>
                                <p className="RemisText">TAUX DE REMIS</p>
                            </Col>

                            <Col xs={4} md={4} lg={5} xl={4}>
                                <p className="RemisPrice">{parseFloat(this.state.remis).toFixed(3).split('.')[0]}%</p>
                            </Col>
                        </Row>
                        }
                    </Col>
                </div>


                <Col xl={12} className="mt-4">
                    <p className="paymentTypeTitle">Paiement par :</p>
                </Col>

                <Row className={window.innerWidth > 767 ? "formRadiosContainer ml-5" : "formRadiosContainer"}>
                    <div className="radioButtonContainer">
                        <label className="butTypeContainer">Carte bancaire
                            <input
                                type="radio"
                                name="statusRadios"
                                value="1"
                                checked={true}
                                onChange={null}
                            />
                            <span className="btnCheckmark"></span>
                        </label>
                    </div>
                </Row>

                <div className="d-flex justify-content-center mt-3">
                    <Col xs={0} />
                    <Col xs={12} xl={8}>
                        {
                            this.state.btnPayLoading
                                ?
                                <Button variant="light" className="submitButton" href="#" onClick={() => null}>
                                    <Spinner animation="grow" variant="light" size="sm" />
                                </Button>
                                :
                                <Button variant="light" className="submitButton" href="#" onClick={() => this.passerPaiement()}>Passer au paiement</Button>
                        }
                    </Col>
                </div>

                {/* <div className="d-flex justify-content-center mt-3">
                    <Col xs={0} />
                    <Col xs={12} xl={8}>
                        <div>
                            {this.state.repServer && JSON.stringify(this.state.repServer) }

                            {this.state.errServer && JSON.stringify(this.state.errServer) }
                        </div>
                    </Col>
                </div> */}
            </>
        );
    };

    passerPaiement = async () => {
        let {
            activeFactures,
            factures,
            totalApayer,
            allClient,
            clientName,
        } = this.state;


        if (activeFactures.length) {
            this.setState({
                btnPayLoading: true
            });

            await localStorage.setItem('Factures', JSON.stringify(activeFactures));
            await localStorage.setItem('nomClient', clientName);
            // await localStorage.setItem('montantTotal',totalApayer);

            setTimeout(() =>

                axios.post(`${config.urlBackExp}generateNumTrans`, {
                    Factures: activeFactures,
                    Total: totalApayer,
                    DetailClient: allClient,
                })
                    .then(async res => {

                        if (res.data) {
                            await localStorage.setItem('numTransaction', res.data);
                            console.log('avant click to pay')
                            let formattedTotal = this.state.totalApayer.toFixed(3).toString().replace('.', '');
                            let returnUrl = `${window.location.origin}/payment-success`;
                            let failUrl = `${window.location.origin}/payment-fail`;

                            // Call Click To Pay:
                            axios.get(`${config.clickToPayUrl}?amount=${formattedTotal}&currency=788&language=en&orderNumber=${res.data}&userName=${config.beeUser}&password=${config.beePassword}&pageView=DESKTOP&returnUrl=${returnUrl}&failUrl=${failUrl}`)
                                .then(async rep => {
                                    console.log("Rep Paiement", "inside click to pay", rep.data);
                                    await localStorage.setItem('orderId', rep.data.orderId);

                                    // redirect Same window :    
                                    if (rep.data.formUrl) {
                                        window.location.href = rep.data.formUrl;
                                    };
                                })
                        };
                    })
                    .catch(err => {
                        const swalWithBootstrapButtons = Swal.mixin({
                            customClass: {
                                confirmButton: 'btnPopup',
                            },
                            buttonsStyling: false
                        })
                        swalWithBootstrapButtons.fire({
                            icon: 'warning',
                            title: 'Oops...',
                            text: 'Erreur de connexion avec le serveur, Réessayer plus tard',
                            confirmButtonText: "D'accord"
                        });
                    })
                , 1500);

        } else {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btnPopup',
                },
                buttonsStyling: false
            })

            if (typeof (factures) === "string" && factures === "pas de facture") {
                swalWithBootstrapButtons.fire({
                    icon: 'warning',
                    title: 'Oops...',
                    text: 'Aucune facture à payer',
                    confirmButtonText: "D'accord"
                });
            } else {
                swalWithBootstrapButtons.fire({
                    icon: 'warning',
                    title: 'Oops...',
                    text: 'Merci de sélectionner au moins une facture',
                    confirmButtonText: "D'accord"
                });
            }

        }
    };

    testSession = () => {
        let now = new Date().getTime();
        let setupTime = localStorage.getItem('setupTime');

        if (now > setupTime || setupTime === null) {
            localStorage.clear();
            this.setState({
                isAuth: false,
                redirectTo: "/",
                redirect: true,
            });
        }
    };

    getFactures = () => {

        const cin = localStorage.getItem('cin');
        let clientNumber = localStorage.getItem('numeroClient');

        axios.get(`${config.url}RechercheFactImpayees/?_format=json&Identifiant=${cin}&NumFixe=${clientNumber}`)
            .then(res => {
                // console.log(res.data.facture);
                this.setState({ factures: res.data.facture });
            })
            .catch(err => {
                // console.log(err)  
            });


        axios.get(`${config.url}getDetailsClient?_format=json&Identifiant=${cin}`)
            .then(res => {
                if (res.data.message.InformationClient !== null) {

                    res.data.message.InformationClient.map(fact =>
                        this.setState({
                            allClient: res.data.message.InformationClient,
                            clientName: fact.Intitule_Client,
                            clientCode: fact.CodeClient,
                        })
                    );
                }
            })
    };

    FacturePourPayer = () => {

        let checkboxes = document.getElementsByName('statusRadios2');
        let table = [];
        let newArrayTable = [];
        let total = 0;
        let remis = 0;

        let i = 0;
        for (let index = 0; index < checkboxes.length; index++) {
            const element = checkboxes[index];
            if (element.checked === true) {
                table[i] = element;
                i++;
            }
        }

        newArrayTable = this.state.factures.filter(function (el) {
            let NumFac = el.NumeroFacture;
            for (let index = 0; index < table.length; index++) {
                const element = table[index].id;
                if (NumFac === element) {
                    return true;
                }
            }
            return false;
        });

        newArrayTable.map((el, index) => {

            total = total + parseFloat(el.MontantAPayer ? el.MontantAPayer : el.Montant_TTC)
            remis = remis + parseFloat(el.TauxRemiseWEB)
        }
        );

        this.setState({
            totalApayer: total,
            remis: remis,
            activeFactures: newArrayTable,
        });

        // document.getElementById('selectAllId').checked = false ;
    };

    // selectAll = () => {
    //     let selectAllButton = document.getElementById('selectAllId');
    //     let checkboxes = document.getElementsByName('statusRadios2');
    //     let total = 0;

    //     if (selectAllButton.checked) {
    //         for (let index = 0; index < checkboxes.length; index++) {
    //             checkboxes[index].checked = true ;
    //         }

    //         this.state.factures.map((el , index) =>
    //           total = total + parseFloat(el.Montant_TTC)
    //         );

    //         this.setState({ 
    //             activeFactures: this.state.factures, 
    //             totalApayer: total,
    //         });

    //     }else {
    //         for (let index = 0; index < checkboxes.length; index++) {
    //             checkboxes[index].checked = false ;
    //         }
    //         this.setState({ 
    //             activeFactures: [], 
    //             totalApayer: 0,
    //         });
    //     }
    //      document.getElementsByName('statusRadios2').values = checkboxes ;
    // };
}
