import React from 'react';
import './App.css';

import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

import { ProtectedRoute } from './ProtectedRoute.js'

// COMPONENTS :
import ScrollToTop from './components/ScrollToTop';

// PAGES :
/* NAVIGATION */
import PaymentSuccess from './app/payment/PaymentSuccess';
import PaymentFail from './app/payment/PaymentFail';
import SplashScreen from './app/navigation/SplashScreen';

/* Auth */
import Authentification from './app/auth/Authentification';

/* Factures */
import Factures from './app/factures/Factures';


class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {

    }
  };



  render() {

    return (
      <Router>
        <ScrollToTop />
        {/* A <Switch> looks through its children <Route>s and renders the first one that matches the current URL.*/}
        <Routes>
          <Route exact path="/" element={<SplashScreen />} />
          <Route exact path="/login" element={<Authentification />} />
          <Route path="/factures" element={<ProtectedRoute/>}/>

          <Route exact path="/payment-success" element={<PaymentSuccess />} />
          <Route exact path="/payment-fail" element={<PaymentFail />} />
          {/* <Route exact path="/factures" element={Factures} /> */}
          {/* <Route path="/article/:slug" element={SoloPage} /> */}
          {/* <Route element={NotFound}/> */}
        </Routes>
      </Router>
    );
  }
}

export default App;
