import React, { Component } from 'react';
import './Authentification.css';
import { config } from '../../constants/AppConfig';


import axios from 'axios';
import $ from 'jquery';
import Swal from 'sweetalert2';

import Reaptcha from 'reaptcha';

import {
    Navigate
} from 'react-router-dom';

import {
    Col,
    Row,
    Image,
    Form,
    Spinner,
} from 'react-bootstrap';

import Header from '../navigation/Header';
import Footer from '../navigation/Footer';


const AUTH_IMAGE = require('../../assets/images/paiement-express.jpg');
const CONTACT = require('../../assets/images/authentification/contact.png');


class Authentification extends Component {

    constructor(props) {
        super(props);

        this.captcha = React.createRef();

        this.state = {
            status: 'Particulier',

            btnLoading: false,

            // Particulier :
            adsl: '',
            cin: '',
            securityCodeParticulier: '',

            // Entreprise
            codeClient: '',
            regCommerce: '',
            securityCodeEntreprise: '',

            buttonClicked: false,
            errorAuth: false,

            // Redirecting :
            redirectTo: "",
            redirect: false,

            verifiedCaptcha : false,
        }
    }

    render() {
        let {
            redirectTo,
            redirect,
        } = this.state;

        return (
            <>
                { redirect &&  <Navigate from='/login' to={redirectTo} /> }

                {/* HEADER */}
                <Header />
                {/* HEADER */}
                   
                <Row className="mx-0">
                    <Col className="p-0">
                        <Image className="image-paiment" src={AUTH_IMAGE} />
                    </Col>
                </Row>

                
                <Row className="authContainer mx-0 p-4">
                    <Col xs={0} md={2} lg={3} xl={4} />

                    <Col xs={12} md={10} lg={9} xl={8}>
                        <Row>
                            <p className="formTitle">Merci de remplir les champs suivants :</p>
                        </Row>

                        <Row className={window.innerWidth > 767 ? "formRadiosContainer ml-5" : "formRadiosContainer"}>

                            <Col xs={0} md={2} lg={1} xl={1} />
                            {/* 
                            <div className="radioButtonContainer my-4">

                                <label className="containerBut">Particulier
                                <input
                                        type="radio"
                                        name="statusRadios"
                                        value="Particulier"
                                        checked={status === 'Particulier'}
                                        onClick={() => this.handleClientTypeChange('Particulier')}
                                        onChange={() => null}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </div> */}

                            {/* <div className="radioButtonContainer">
                           
                            <label className="containerBut">Entreprise
                                <input 
                                    type="radio" 
                                    name="statusRadios" 
                                    value="Entreprise"
                                    checked={status === 'Entreprise'}
                                    onClick={() => this.handleClientTypeChange('Entreprise')} 
                                    onChange={() => null} 
                                />
                                <span className="checkmark"></span>
                            </label>

                        </div> */}
                        </Row>
                        {this.renderAuthForm('Particulier')}
                    </Col>
                </Row>

                <Row className="justify-content-center mx-0">
                    <Col style={{ textAlign: 'center', cursor:'pointer' }} onClick={() => window.open("https://sitebee.lezarts-digital.com/particulier/faq")}>
                        <Image src={CONTACT} className="contactImage" />
                        <p className="contactImageTitle">CONTACTEZ UN EXPERT EN LIGNE</p>
                        <p className="contactPhoneTitle">+216 71 100 440</p>
                        <p className="contactImageDesc">Notre assistance en ligne est à votre disposition</p>
                    </Col>
                </Row>

                {/* FOOTER */}
                <Footer />
                {/* FOOTER */}
            </>
        )
    }

    onVerify = recaptchaResponse => {

        // console.log("Rep Captcha", recaptchaResponse);

        this.setState({
            verifiedCaptcha: true
        });
    };

    renderAuthForm = (status) => {

        if (status === 'Particulier') {
            return (
                <>
                    <Form className={window.innerWidth > 767 && "ml-3"}>
                      
                        <Form.Group as={Row} controlId="adsl">
                            <Form.Label className="authLabel px-0" column xs={12} sm={4} md={5} lg={4} xl={2}>
                                Numéro ADSL/VDSL
                            </Form.Label>

                            <Col xs={12} sm={6} md={6} lg={5} xl={3}>
                                <Form.Control className="ml-3" type="text" value={this.state.adsl} onChange={(event) => this.setState({ adsl: event.target.value })} maxLength="8" />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="my-4" controlId="cin">
                            <Form.Label className="authLabel px-0" column xs={12} sm={4} md={5} lg={4} xl={2}>
                                Numéro CIN
                            </Form.Label>

                            <Col xs={12} sm={6} md={6} lg={5} xl={3}>
                                <Form.Control className="ml-3" type="text" value={this.state.cin} onChange={(event) => this.setState({ cin: event.target.value })}  />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="d-flex" controlId="formHorizontalEmail">
                            <Form.Label className="authLabel px-0 my-auto" column xs={10} sm={4} md={5} lg={4} xl={2}>
                                Code sécurité
                            </Form.Label>

                            <Col xs={10} sm={6} md={6} lg={5} xl={3} className="d-flex">
                                <Reaptcha 
                                    ref={e => (this.captcha = e)}
                                    className="m-auto"
                                    sitekey="6LcvPaYZAAAAAOPD6RqRRgBdgk1myYnd2txswFOq" 
                                    onVerify={() => this.onVerify()} 
                                />
                            </Col>
                            </Form.Group>

                        <Form.Group as={Row} controlId="formHorizontalEmail2">
                            <Form.Label column xs={5} md={6} lg={5} xl={2}></Form.Label>

                            <Col xs={6} md={5} lg={4} xl={3} className="d-flex mt-3">
                                {
                                    this.state.btnLoading
                                    ?
                                    <button 
                                        type={"button"} className="submitButton btn btn-light ml-auto" 
                                        disabled={ !((this.state.adsl.length >= 8 && this.state.cin.length >= 8) && this.state.verifiedCaptcha ) } 
                                        onClick={() => this.verifParticuler()}  
                                    >
                                        <Spinner animation="grow" variant="light" size="sm"/>
                                    </button>
                                    :
                                    <button 
                                        type={"button"} className="submitButton btn btn-light ml-auto" 
                                        disabled={ !((this.state.adsl.length >= 8 && this.state.cin.length >= 5) && this.state.verifiedCaptcha ) } 
                                        onClick={() => this.verifParticuler()}  
                                    >
                                    Envoyer
                                    </button>
                                }
                                {/* <button  
                                    type={"button"}
                                    className="submitButton btn btn-light ml-auto" 
                                    disabled={ !((this.state.adsl.length >= 8 && this.state.cin.length >= 8) && this.state.verifiedCaptcha ) } 
                                    onClick={() => this.verifParticuler()}  
                                >

                                    Envoyer
                                </button> */}
                            </Col>
                        </Form.Group>
                    </Form>
                </>
            );
        } else {
            return (
                <>
                    <Form className={window.innerWidth > 767 && "ml-3"}>
                        <Form.Group as={Row} controlId="formHorizontalEmail">
                            <Form.Label className="authLabel" column sm={4} md={5} lg={4} xl={3}>
                                Code client
                            </Form.Label>

                            <Col sm={6} md={6} lg={5} xl={4}>
                                <Form.Control type="number" value={this.state.codeClient} onChange={(event) => this.setState({ codeClient: event.target.value })} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formHorizontalEmail">
                            <Form.Label className="authLabel" column sm={4} md={5} lg={4} xl={3}>
                                Registre du commerce
                            </Form.Label>

                            <Col sm={6} md={6} lg={5} xl={4}>
                                <Form.Control type="number" value={this.state.regCommerce} onChange={(event) => this.setState({ regCommerce: event.target.value })} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formHorizontalEmail">
                            <Form.Label className="authLabel" column sm={4} md={5} lg={4} xl={3}>
                                Code sécurité
                            </Form.Label>

                            <Col sm={6} md={6} lg={5} xl={4}>
                                <Form.Control type="text" value={this.state.securityCodeEntreprise} onChange={(event) => this.setState({ securityCodeEntreprise: event.target.value })} />
                            </Col>
                        </Form.Group>
                        
                        <Form.Group as={Row} controlId="formHorizontalEmail">
                            <Form.Label className="authLabel" column sm={4} md={5} lg={4} xl={3}>
                               
                            </Form.Label>

                            <Col sm={6} md={6} lg={5} xl={4}>
                                {/* <Form.Control type="text" value={this.state.securityCodeEntreprise} onChange={(event) => this.setState({ securityCodeEntreprise: event.target.value })} /> */}
                               
                                
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formHorizontalEmail">
                            <Form.Label className="mr-1" column xs={5} md={6} lg={5} xl={4}></Form.Label>

                            <Col xs={6} md={5} lg={4} xl={3} className="mt-3">
                                <a className="submitButton btn btn-light" href="/factures">Envoyer</a>
                            </Col>
                        </Form.Group>

                    </Form>
                </>
            );
        }
    };

    handleClientTypeChange(param) {
        this.setState({
            status: param,
            // Particulier :
            adsl: '',
            cin: '',
            securityCodeParticulier: '',

            // Entreprise
            codeClient: '',
            regCommerce: '',
            securityCodeEntreprise: '',
        });

        // console.log("result",e.target.value);
        // this.setState({ status : String(e.target.value) });
    };
    
    verifParticuler = () => {

        let {
            adsl,
            cin
        } = this.state;

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: 'btnPopup',
            },
            buttonsStyling: false
        });

        if(adsl.length < 8 ) {
            if(adsl === "") {
                
                $("#adsl").css("border-color", "#FF5722");

                swalWithBootstrapButtons.fire({
                    icon: 'warning',
                    title: 'Oops...',
                    text: 'Le champ Numéro ADSL/VDSL est vide',
                    confirmButtonText: "D'accord"
                });
                this.setState({
                    verifiedCaptcha: false
                });
                return;    
            }
            $("#adsl").css("border-color", "#FF5722");
            swalWithBootstrapButtons.fire({
                icon: 'warning',
                title: 'Oops...',
                text: 'Le champ Numéro ADSL/VDSL doit contenir 8 chiffres',
                confirmButtonText: "D'accord"
            });
            this.setState({
                verifiedCaptcha: false
            });
            return;

        } else if(cin.length < 5) {
            if(cin === "") {

                $("#cin").css("border-color", "#FF5722");

                swalWithBootstrapButtons.fire({
                    icon: 'warning',
                    title: 'Oops...',
                    text: 'Le champ Numéro CIN est vide',
                    confirmButtonText: "D'accord"
                });
                this.setState({
                    verifiedCaptcha: false
                });
                return;    
            }
            $("#cin").css("border-color", "#FF5722");

            swalWithBootstrapButtons.fire({
                icon: 'warning',
                title: 'Oops...',
                text: 'Le champ Numéro CIN doit contenir 8 chiffres',
                confirmButtonText: "D'accord"
            });
            this.setState({
                verifiedCaptcha: false
            });
            return;
        } else {
            // ALL IS VALID :
            $("#adsl").css("border-color", "#FFB613");
            $("#cin").css("border-color", "#FFB613");

            this.setState({
                btnLoading: true
            }); 
            
            axios.get(`${config.url}RechercheFactImpayees/?_format=json&Identifiant=${this.state.cin}&NumFixe=${this.state.adsl}`)
                .then(res => {
                    
                    if (res.data.status) {
                        var now = new Date().getTime();
                        now = now + 3600000;
                        localStorage.setItem('setupTime', now);
                        localStorage.setItem('cin', this.state.cin);
                        localStorage.setItem('numeroClient', this.state.adsl);
                        // Redirect User To MiddleWare :
                        this.setState({
                            redirectTo: '/',
                            redirect: true,
                        });
                        
                    } else {
                        
                        if(res.data.facture === "Aucune facture") {
                            var now = new Date().getTime();
                            now = now + 3600000;
                            localStorage.setItem('setupTime', now);
                            localStorage.setItem('cin', this.state.cin);
                            localStorage.setItem('numeroClient', this.state.adsl);

                            // Redirect User To MiddleWare :
                            this.setState({
                                redirectTo: '/',
                                redirect: true,
                            });
                        // } else if (res.data.facture === "Vérifiez vos informations saisies!") {
                        } else {

                            swalWithBootstrapButtons.fire({
                                icon: 'warning',
                                title: 'Oops...',
                                text: 'Vos informations de connexion sont incorrectes',
                                confirmButtonText: "D'accord"
                            });
    
                            this.captcha.reset();
                            
                            this.setState({ 
                                btnLoading: false,
                                verifiedCaptcha: false,
                            });

                            $("#adsl").css("border-color", "#FF5722");
                            $("#cin").css("border-color", "#FF5722");
                        };
                    }
                })
                .catch(err => {
                    // console.log("Error",err);
                    this.setState({
                        btnLoading: false
                    });
                    swalWithBootstrapButtons.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Erreur de connexion avec le serveur, Réessayer plus tard',
                        confirmButtonText: "D'accord"
                    });
                })
        }
    };
}

export default Authentification;
