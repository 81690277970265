import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };

    // Scroll to top when the location changes
    scrollToTop();
  }, [location]);

  return null;
};

export default ScrollToTop;
