import React from "react";
import { Route, Navigate } from "react-router-dom";
import Factures from './app/factures/Factures';
// import auth from "./auth";

export const ProtectedRoute = ({
  component: Component,
  ...rest
}) => {
  let cin = localStorage.getItem('cin');
  let numClient = localStorage.getItem('numeroClient');
  let numAdsl = localStorage.getItem('adsl');

  if (cin !== null && (numClient !== null || numAdsl !== null)) {
    return <Factures />;
  } else {
    return (
      <Navigate
        to={{
          pathname: "/login",
          state: {
            from: rest.location
          }
        }}
      />
    );
  }
};
