import React, {Component} from 'react';
import './Footer.css';

import {
    Col,
    Row,
    Image,
} from 'react-bootstrap';

// const FOOTER = require('../../assets/images/footer.png');

// ICONS :
const LOGO = require('../../assets/images/footer/bee_white.png');
const FB = require('../../assets/images/footer/fb_icon.png');
const INSTA = require('../../assets/images/footer/insta_icon.png');
const IN = require('../../assets/images/footer/linked_icon.png');
const CALL = require('../../assets/images/footer/call_icon.png');
const MAIL = require('../../assets/images/footer/mail_icon.png');


class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            test : 'TEST'
        }
    }


    render() {
        return (
            // <Image src={FOOTER} fluid className="footerImage" />
            <Row className="footerContainer mx-0">
                <Col>
                <Row className="d-flex justify-content-center mt-3">
                    <Col xs={5} sm={3} md={3} lg={2} xl={2}>
                        <Image src={LOGO} fluid  />
                    </Col>
                </Row>

                <Row className="d-flex justify-content-center mt-3">    
                    <Col xs={10} sm={7} md={7} lg={8} xl={7}>
                        <Row className="d-flex justify-content-center">
                            <Col xs={2} sm={2} md={2} lg={1} xl={1}>
                                <a href="https://www.facebook.com/bee.tunisie/" target="_blank">
                                    <Image src={FB} fluid className="socialLogo" />
                                </a>
                            </Col>
                        
                            <Col xs={2} sm={2} md={2} lg={1} xl={1}>
                                <a href="https://instagram.com/beetunisie/" target="_blank">
                                    <Image src={INSTA} fluid className="socialLogo" />
                                </a>
                            </Col>
                        
                            <Col xs={2} sm={2} md={2} lg={1} xl={1}>
                                <a href="https://www.linkedin.com/company/bee-tunisie/" target="_blank">
                                    <Image src={IN} fluid className="socialLogo" />
                                </a>
                            </Col>
                        </Row>
                    </Col>    
                </Row>

                <hr className="hrFooter" />

                <Row className="d-flex justify-content-center mt-1">
                    <p className="contactezText">Contactez nous</p>
                </Row>
                
                <Row className="d-flex justify-content-center mt-2">
                    <Image src={CALL} fluid className="contactIcon mr-3" />
                    <p className="beeContactInfo">+216 71 100 440</p>
                    
                </Row>

                <Row className="d-flex justify-content-center mt-1">
                    <Image src={MAIL} fluid className="contactIcon1 mr-3" />   
                    <p className="beeContactInfo">contact@bee.net.tn</p>
                </Row>

                <Row className="d-flex justify-content-center mt-3">
                    <p className="copyRightText">Copyright 2020. All Rights Reserved</p>
                </Row>

                <Row className="d-flex justify-content-center">
                    <p className="lzText">LEZARTS DIGITAL</p>
                </Row>
                </Col>
            </Row> 
        );
    }
}

export default Footer;

