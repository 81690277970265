import React, { Component } from 'react'
import './Payment.css';
import { config } from '../../constants/AppConfig';

// import { AiOutlineEye } from 'react-icons/ai';
import axios from 'axios';
// import Swal from 'sweetalert2';

import Header from '../navigation/Header';
import Footer from '../navigation/Footer';


import {
    Navigate
} from 'react-router-dom';

import {
    Container,
    Col,
    Row,
} from 'react-bootstrap';

import Loading from '../../components/Loading';

export default class PaymentSuccess extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            // loading: false,
            redirectTo: '',
            redirect: false,

            nomClient: '',
            numTrans: '',
        }
    };

    componentDidMount = async () => {

        let url = window.location;

        let search_params = new URLSearchParams(url.search);

        if (search_params.has('orderId')) {
            let orderIdFromLink = search_params.get('orderId');
            let TypeAbonn = '1';
            let orderIdFromLocal = await localStorage.getItem('orderId');
            let activeFacturesFromLocal = await JSON.parse(localStorage.getItem('Factures'));
            let NumTransactionFromLocal = await localStorage.getItem('numTransaction');
            let clientName = await localStorage.getItem('nomClient');
            let clientNumber = await localStorage.getItem('numeroClient');
            let clientCin = await localStorage.getItem('cin');

            this.setState({
                nomClient: clientName,
                numTrans: NumTransactionFromLocal,
            });

            if (orderIdFromLink === orderIdFromLocal && orderIdFromLink !== null) {

                // Send To Bee :
                await activeFacturesFromLocal.map(async (facture, index) => {
                    if (index > 0) {
                        setTimeout(async () =>
                            await axios.get(`${config.url}PaiementFact?_format=json&NumeroFacture=${facture.NumeroFacture}&Montant=${facture.MontantAPayer ? facture.MontantAPayer : facture.Montant_TTC}&NumeroTransaction=${orderIdFromLocal}&TypeAbonnement=${TypeAbonn}`)
                                .then((rep) => {
                                    console.log("payement done")
                                })
                            , 10000)
                    } else {
                        await axios.get(`${config.url}PaiementFact?_format=json&NumeroFacture=${facture.NumeroFacture}&Montant=${facture.MontantAPayer ? facture.MontantAPayer : facture.Montant_TTC}&NumeroTransaction=${orderIdFromLocal}&TypeAbonnement=${TypeAbonn}`)
                            .then((rep) => { /* console.log(`Reponse Bee ${index}`,rep.data);*/ })
                    }
                });

                // Send To BackEnd Express Payment Status:
                await axios.post(`${config.urlBackExp}transactionSuccess`, {
                    Factures: activeFacturesFromLocal,
                    OrderId: orderIdFromLocal,
                    NumTransaction: NumTransactionFromLocal,
                    Status: 'success',
                })
                    .then(async rep => {
                        console.log("send to back express and reactivation")
                        // Voir s'il a payé tt ses factures :
                        setTimeout(async () => {
                            await axios.get(`${config.url}RechercheFactImpayees/?_format=json&Identifiant=${clientCin}&NumFixe=${clientNumber}`)
                                .then(async rep => {

                                    console.log("Resultat aprés paiement, recherche fact impayés", rep.data);

                                    if (rep.data.facture === "Aucune facture") {

                                        await axios.post(`${config.urlReactiv}`, {
                                            "tel_adsl": clientNumber.toString(),
                                            "type": "2",
                                        })
                                            .then((rep) => {
                                                console.log("Reactivation", rep.data);

                                                localStorage.clear();

                                                setTimeout(() =>
                                                    this.setState({
                                                        loading: false,
                                                    })
                                                    , 5000);
                                            })
                                            .catch(err => {
                                                console.log("Reactivation error", err);

                                                localStorage.clear();

                                                setTimeout(() =>
                                                    this.setState({
                                                        loading: false,
                                                    })
                                                    , 5000);
                                                // console.log("Erreur Paiement",err)
                                            })
                                    } else {
                                        console.log("pas de réactivation")
                                        localStorage.clear();

                                        setTimeout(() =>
                                            this.setState({
                                                loading: false,
                                            })
                                            , 5000);
                                    }
                                })
                        }, 5000);
                    })
            } else {
                this.setState({
                    loading: false,
                    redirectTo: '/',
                    redirect: true,
                });
            }
        } else {
            this.setState({
                loading: false,
                redirectTo: '/',
                redirect: true,
            });
        }
    };

    render() {
        let {
            loading,
            redirectTo,
            redirect,
            nomClient,
            numTrans,
        } = this.state;

        if (loading) {
            return (<Loading />)
        } else {
            return (
                <>

                    {/* HEADER */}
                    <Header />
                    {/* HEADER */}

                    {redirect && <Navigate from='/payment-success' to={redirectTo} />}

                    <Row className="backimage">
                        <Col className="d-flex">
                            <h1 className="textBack"> STATUS PAIEMENT </h1>
                        </Col>
                    </Row>

                    <Container fluid={window.innerWidth > 1200 ? true : false}>
                        <Row>
                            <Col xs={0} md={0} lg={0} xl={1} />

                            <Col xs={12} md={12} lg={12} xl={10} className="leftFactureContainer">
                                <div className="payTitle">Félicitations</div>

                                <div className="payName">Bonjour M/MME {nomClient}</div>

                                <div className="payDesc">Nous vous informons que le paiement des factures ayant le numéro de transaction {numTrans} a été effectué avec succès</div>

                                <div className="payName">Nous vous remercions pour votre fidélité</div>
                            </Col>

                            <Col xs={0} md={0} lg={0} xl={1} />
                        </Row>
                    </Container>

                    {/* FOOTER */}
                    <Footer />
                    {/* FOOTER */}
                </>
            );
        }
    };


}