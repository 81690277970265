import React, { Component } from 'react';


import Lottie from 'react-lottie';
import Loader from '../../assets/json/hsan.json';
import './SplashScreen.css';

import {
    Navigate
} from 'react-router-dom';

import {
    Col,
    Row,
    Image,
} from 'react-bootstrap';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Loader,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};
const LOGO = require('../../assets/images/footer/bee_white.png');

export default class SplashScreen extends Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectTo: "",
            redirect: false,
        };
    }

    componentDidMount() {
        setTimeout( async () => {
            let now = new Date().getTime();
            let setupTime = localStorage.getItem('setupTime');
            
            if ( now > setupTime || setupTime === null ) {
                localStorage.clear();
                
                await this.setState({
                    redirectTo: "/login",
                    redirect: true,
                });
            } else {
                await this.setState({
                    redirectTo: "/factures",
                    redirect: true,
                })
            }
        },1500);    
    };

    render() {
        let {
            redirectTo,
            redirect
        } = this.state;

        return (
            <>
            { redirect &&  <Navigate from='/' to={redirectTo}  /> }

            <div className="background-splash">
                <Row className="m-auto">
                    <Col sm={12} md={12} lg={12} > 
                    <Image src={LOGO} fluid className="logo-splash m-auto" />
                    </Col>

                    <Col sm={12} md={12} lg={12} >
                    <Lottie
                            options={defaultOptions}
                            height={100}
                            width={200}
                            isStopped={false}
                            isPaused={false}
                            />
                    </Col>
                </Row>
            </div>
            </>
        )
    }
}
