import React, { Component } from 'react';
import './Header.css';
import img from '../../assets/images/authentification/toBack.png'
import {
    Navigate
} from 'react-router-dom';

import {
    Col,
    Row,
    Image,
    Spinner,
    Button
} from 'react-bootstrap';

const LOGO = require('../../assets/images/logo-bee.png');


export default class Header extends Component {

    constructor(props) {
        super(props);


        this.state = {
            redirectTo: '',
            redirect: false,

            showLogout: false,
            logoutLoading: false,
        };
    };

    render() {
        let {redirect, redirectTo,logoutLoading} = this.state;

        let { showLogout } = this.props;
        return (
            <>
            {
                redirect && 
                <Navigate
                    to={redirectTo}
                    // to={{
                    //     pathname: redirectTo,
                    //     state: { from: 'MiddleWare' }
                    // }}
                />
            }
            <Row className={"justify-content-center mx-0"}>

                <Col style={{textAlign: 'left'}}>

                <Button  href="https://bee.net.tn/"  className="backBtn" >
                   
                    <img src={img}/>
                
                </Button>
                </Col>
                <Col style={{textAlign: 'center'}}>

                    <Image src={LOGO} className="logo-header" />
                </Col>
                {
                    showLogout && !logoutLoading &&
                    <div className="d-flex">
                        <a className="logoutBtn my-auto mr-4" href="/#" onClick={() => this.logout()}>Déconnexion</a>
                    </div>
                }
                {
                    logoutLoading
                    &&
                    <div className="d-flex">
                        <div className="logoutBtn my-auto mr-5">
                            <Spinner animation="border" />
                        </div>
                    </div>
                }
            </Row>

            </>
        )
    }

    logout = () => {
        localStorage.clear();

        this.setState({
            logoutLoading: true
        });

        setTimeout(() => 
            this.setState({
                redirectTo : '/login',
                logoutLoading: false,
                redirect: true,
            })
        ,1500);

    };
}
