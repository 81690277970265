import React, { Component } from 'react';

import Lottie from 'react-lottie';
import Loader from '../assets/json/hsan.json';
import '../app/navigation/SplashScreen.css';

import {
    Col,
    Row,
    Image,
} from 'react-bootstrap';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Loader,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const LOGO = require('../assets/images/footer/bee_white.png');

export default class Loading extends Component {

    render() {
        return (
            <>
            <div className="background-splash">
                <Row className="m-auto">
                    <Col sm={12} md={12} lg={12} > 
                    <Image src={LOGO} fluid className="logo-splash m-auto" />
                    </Col>

                    <Col sm={12} md={12} lg={12} >
                    <Lottie
                            options={defaultOptions}
                            height={100}
                            width={200}
                            isStopped={false}
                            isPaused={false}
                            />
                    </Col>
                </Row>
            </div>
            </>
        )
    }
}
