/* 
*
* ALL APP CONFIG IN ONE FILE
*
*/

//Config :
const config = {
    // url : 'http://41.226.166.25:8080/WebServicePostBee/web/api/',
    // url : 'http://41.231.103.33:8080/WebServicePostBee/web/api/',
    urlBackExp: 'https://beebo.bee.net.tn/public/api/', // BACK EXPRESS LZD DEMO
    // urlBackExp: 'https://beebo.bee.net.tn/',

    url: 'https://easybo.bee.net.tn:17443/api/',
    // url: 'https://easybo.bee.net.tn:17443/api/',
    // url: 'https://41.231.103.33/WebServicePostBee/web/api/',

    urlReactiv: 'https://easybo.bee.net.tn:17443/paiement_reactivation.php/', //url test reactivation 
    clickToPayUrl: 'https://ipay.clictopay.com/payment/rest/register.do',

    beeUser: '100090389',
    beePassword: '2sy38GiPJ'
};


// // Config test :
// const config = {
//     urlBackExp: 'https://beebo.bee.net.tn/public/api/', // BACK EXPRESS LZD DEMO
//     url: 'http://41.231.228.100:8086/api/',
//     urlReactiv: 'https://easybo.bee.net.tn:17443/paiement_reactivation.php/', //url test reactivation 
//     clickToPayUrl: 'https://test.clictopay.com/payment/rest/register.do',

//     beeUser: '100090389',
//     beePassword: 'nL2FidF98'
// };

export {
    config,
};